.aboutCont {
    margin-top: var(--navBar-height);
    min-height: calc(100vh - var(--navBar-height));
    padding-bottom: 5rem;
}

.about_title {
    margin-bottom: 0.5rem;
}

.about_subtitle{
    font-size: 1rem;
    color: var(--text-dark-color);
    margin-bottom: 1rem;
}

.homeTeamCardDesireeImgClass, .homeTeamCardMartinImgClass, .homeTeamCardCamilaImgClass, .homeTeamCardJessicaImgClass {
    width: 85%;
    height: 85%;
    object-position: top;
    object-fit: cover;
    transform: translateY(1rem);
}

.homeTeamCardJessicaImgClass {
    object-position: bottom;
}

.homeTeamCardDesireeImgClass {
    filter: brightness(105%) contrast(125%) grayscale(100%);
}

.homeTeamCardCamilaImgClass {
    filter: brightness(130%) grayscale(100%);
}

.aboutLine {
    margin-top: 0;
}

