.contSpinners {
    position: fixed;
    width: 100%;
    top: var(--navBar-height);
    height: calc(100vh - var(--navBar-height));
    left: 0;
    z-index: 9000;
}

.imageSpinner {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.5;
    pointer-events: none;
}