.homeConcernsCard_cont {
    width: var(--concerns-cards-width);
    min-width: var(--concerns-cards-width);
    max-width: 100%;
    height: var(--concerns-cards-height);
    position: relative;
    margin: var(--concerns-cards-margin);
}

.homeConcernsCard_img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: grayscale(100%);
}

.homeConcernsCard_textCont {
    position: absolute;
    width: 80%;
    min-height: 4.5rem;
    left: 0;
    right: 0;
    margin: auto;
    top: 100%;
    transform: translateY(-50%);
    background-color: var(--color-web-1);
    border-bottom: 4px solid var(--color-web-4);
    padding: 0.5rem 0.25rem;
}

.homeConcernsCard_mainText {
    font-size: 0.9rem;
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 0.5rem;
}

.homeConcernsCard_secondaryText {
    font-family: 'Dynalight', cursive;
    font-size: 1.1rem;
}

