.contMenu {
    position: relative;
    height: 100%;
    background-color: var(--color-web-2);
    z-index: 1000;
    padding-right: 1rem;
    border-bottom: 1px dashed var(--border-with-bg-dark);
}

.tapaMenu {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 250;
    pointer-events: none;
}

.menu {
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    z-index: 260;
}

.opcion {
    font-size: 0.75rem;
    font-weight: 200;
    height: 100%;
    padding: 0 1.5rem;
    color: var(--text-light-color);
    text-transform: uppercase;
    font-weight: 300;
    letter-spacing: 2px;
}

.firstOption {
    padding-left: 0;
}

.opcion:hover {
    color: var(--color-navbar-hover);
}

.iconoMenu {
    position: absolute;
    height: 50%;
    right: 0.75rem;
    top: 0;
    bottom: 0;
    margin: auto;
    filter: invert(100%);
    display: none;
    cursor: pointer;
    z-index: 270;
}

.navBarOptionColorOn {
    color: var(--color-navbar-hover)
}

@media only screen and (orientation: portrait) {

    .navBarContLogoEidioma {
        left: 0.75rem;
    }
    
    .navBarLogo {
        height: 70%;
    }

    .contMenu {
        border-bottom: 1px solid var(--border-with-bg-dark);
    }
}


/******************************** Clases que se cargan dinamicamente desde el javascript del navbar al superar o bajar de ciertos REM ******************************************/

.iconoMenuON {
    display: flex;
}

.menuOFF {
    display: none;
}

.menuBaja, .menuSube {
    display: flex;
    position: fixed;
    width: 14.5rem;
    top: var(--navBar-height);
    right: 0;
    flex-direction: column;
    z-index: 190;
    height: fit-content;
    background-color: var(--color-web-2);
    animation-duration: 0.35s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    border-top: 1px solid var(--navBar-border-lines-color);
}

.menuBaja {
    animation-name: menuBaja;
}

.menuSube {
    animation-name: menuSube;
}

@keyframes menuBaja {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes menuSube {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(calc(100% + 1px));   /* 1px es para que no se vea el borde izquierdo al cerrar el menu */
    }
}

.opcion2 {
    padding: 1rem 0;
    padding-left: 1.5rem;
    width: 100%;
    font-weight: 400;
    justify-content: flex-start;
    font-size: 0.8rem;
    border-bottom: 1px solid var(--navBar-border-lines-color);
}

.opcion2:hover {
    color: var(--color-navbar-hover);
    animation-name: rightMove;
    animation-duration: 0.25s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
}

@keyframes rightMove {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(1.5rem);
    }
}


