.cvCont {
    margin-top: var(--navBar-height);
    min-height: calc(100vh - var(--navBar-height));
    position: relative;
}

.cvCont * {
    color: var(--color-web-1);
}

.cvCont::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url(../../../public/images/cv/bg.jpg);
    background-size: cover;
    filter: var(--bg-filter);
    z-index: -1;
}

.cvSheetCont {
    width: 35rem;
    max-width: 95%;
    background-color: white;
    min-height: 100%;
    padding: 1rem 0;
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.cvSheetImg {
    float: left;
    width: 40%;
    aspect-ratio: 3/3.25;
    object-fit: cover;
    /* object-position: right; */
    padding-right: 1rem;
    padding-bottom: 0.5rem;
    padding-left: 2rem;
    filter: grayscale(50%);
}

.cvSheetMainTexts {
    align-items: flex-start;
    width: 60%;
    padding-bottom: 1.5rem;
}

.cvSheetName {
    color: inherit;
    font-size: 1.5rem;
    font-weight: 600;
    text-align: left;
    text-transform: uppercase;
}

.cvSheetTitle {
    width: 100%;
    background-color: var(--color-web-2);
    padding: 0.5rem;
    padding-left: 0.25rem;
    color: #f6af64;
    justify-content: flex-start;
    margin: 0.5rem 0;
    font-weight: 400;
}

.cvSheetSubTitle {
    font-style: oblique;
    font-weight: 400;
    text-align: left;
}

.cvSheetTextCont {
   padding: 1rem;
   padding-top: 0;
   padding-left: 2rem;
   padding-bottom: 0;
   text-align: left;
}

.cvSheetText, .cvSheetTextMains, .cvSheetTextTitle {
    text-align: left;
    margin-bottom: 0.5rem;
    font-weight: 300;
}

.cvSheetText {
    margin-bottom: 0.5rem;
    line-height: 1.5;
}

.cvSheetTextBold {
    font-weight: 500;
}

.cvSheetTextMains {
    font-weight: 500;    
    margin-bottom: 0.25rem;
    margin-top: 0.25rem;
}

.cvSheetTextMainsTop {
    font-weight: 600;
    margin-top: 1rem;
    margin-bottom: 0;
}

.cvSheetTextTitle {
    font-weight: 700;
    max-width: 100%;
    margin-bottom: 0;
    color: var(--color-web-2);
    line-height: 1.3;
    font-size: 17px;
}

.cvSheetTextTitleLong {
    font-weight: 500;
    margin-bottom: 1.25rem;
}

.cvSheetTextCont p:first-child {
    margin-top: 0;
}

.cvSheetTextLine {
    width: calc(100%);
    height: 3px;
    background-color: var(--color-web-2);
    margin: 1rem 0;
}

.cvHeadCont {
    display: none;
}

.cvSheetSenna {
    line-height: 1.5;
    margin-bottom: 0;
}

.cvSheetSenna_paddingBotton0 {
    padding-bottom: 0 !important;
}

@media only screen and (orientation: portrait) {

    .cvCont {
       align-items: flex-start;
    }

    .cvSheetImg {
        width: 50%;
        padding-left: 1rem;
        aspect-ratio: 3/4;
    }

    .cvSheetMainTexts {
        width: 50%;
    }

    .cvSheetSubTitle {
        font-size: 14px;
    }

    .cvSheetTextCont {
        padding-left: 1rem;
    }

    .cvSheetTextTitle {
        margin-top: 1.5rem;
    }

    .cvSheetTextCont p:first-child {
        margin-top: 1.5rem;
    }

    /*******************************/

    .cvSheetImgPortrait {
        float: unset;
        height: 11rem;
        object-position: top;
    }

    .cvHeadCont {
        align-items: flex-start;
    }

    .cvSheetSubTitle {
        margin-top: 0.25rem;
    }

    .cvSheetTextTitle {
        margin-top: 0.5rem;
        font-size: 16px;
    }

    .cvHeadLandsCape {
        display: none;
    }

    .cvHeadCont {
        display: flex;
    }

    .cvSheetTextTitleFirstPortrait {
        margin-top: 0.75rem !important;
    }
   
}