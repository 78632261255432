.areasCont {
    justify-content: flex-start;
    position: relative;
}

.areasInternal_cont {
    width: 50rem;
    max-width: 100%;
}

.areas_title, .areas_title * {
    line-height: 1.25;
    font-size: 1.5rem;
    margin-bottom: 0;
} 

.areasList_cont {
    align-items: flex-start;
    margin: 1.75rem 0;
}

.areasList_cont li, .areasInternal_cont p {
    list-style: disc;
    font-size: 1.25rem;
    color: var(--text-dark-color);
    font-weight: 500;
    margin-bottom: 0.75rem;
}

.areasInternal_cont p {
    font-weight: 400;
}

.areas_bgImg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    opacity: 0.05;
    filter: grayscale(100%);
}

@media only screen and (orientation:portrait) {

    .areasInternal_cont {
       align-items: flex-start;
    }

    .areasList_cont {
        margin-left: 1.35rem;
    }

    .areas_title, .areasInternal_cont p {
        text-align: left;
    }

}