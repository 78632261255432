.contactCont {
    position: relative;
    margin-top: var(--navBar-height);
    min-height: calc(100vh - var(--navBar-height));
    width: 100%;
}

.contactCont::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url(../../../public/images/cv/bg.jpg);
    background-size: cover;
    filter: var(--bg-filter);
    z-index: -1;
}

.seccionContact {
    --ancho-form: 32.5rem;
    height: 100%;
    width: 100%;
}

.contFormContacto {
    width: 100%;
    height: fit-content;
    z-index: 100;
}

.contTituloContacto {
    grid-area: titulo;
    width: 100%;
    justify-content: flex-start;
}

.tituloContacto {
    font-size: 1.75rem;
    margin-bottom: 0.75rem;
    font-weight: 500;
}

.telEmailCont {
    margin-bottom: 2rem;
    width: var(--ancho-form);
    justify-content: flex-start;
    max-width: 100%;
}

.tel-contacto, .email-contacto {
    justify-content: flex-start;
}

.tel-contacto h2, .email-contacto h2{
    color: var(--text-light-color);
    font-size: 0.925rem;
    font-weight: 400;
    opacity: 1;
    margin-right: 2rem;
}

.email-contacto h2 {
    margin: 0;
}

.tel-contacto {
    grid-area: contacto_tel;
}

.imgContactoPhone, .imgContactoEmail {
    height: 1.35rem;
    margin-right: 0.5rem;
}

.email-contacto {
    grid-area: contacto_email;
}

.form {
    --grid-gap: 0.75rem;
    display: grid;
    grid-template-areas: "titulo titulo" 
                         "nombre apellido"
                         "email telefono"
                         "mensaje mensaje";
    grid-gap: var(--grid-gap);
    grid-template-columns: calc(50% - var(--grid-gap) / 2) calc(50% - var(--grid-gap) / 2);
    width: var(--ancho-form);
    max-width: 90vw;
}

.form input, .form textarea {
    padding: 0.5rem 0.5rem;
    border: none;
    color: var(--color-web-2);
    font-weight: 700;
    text-align: left;
    letter-spacing: normal;
}

.form input::placeholder, .form textarea::placeholder {
    font-weight: 700;
    letter-spacing: 0.1rem;
    font-size: 0.7rem;
    opacity: 0.60;
    text-align: left;
}

.form input:focus, .form textarea:focus {
    outline: 3px solid gray;
}

.form textarea {
    resize: none;
}

.formNom {
    grid-area: nombre;
    min-height: 2rem;
    width: 100%;
}

.formTel {
    grid-area: telefono;
    min-height: 2rem;
    width: 100%;
}

.formEmail {
    grid-area: email;
    min-height: 2rem;
    width: 100%;
}

.formApellido {
    grid-area: apellido;
    min-height: 2rem;
    width: 100%;
}

.formMsj {
    grid-area: mensaje;
    height: 10rem;
    width: 100%;
}

.contBotonContacto {
    width: var(--ancho-form);
    max-width: 90vw;
}

.boton-contacto {
    margin-top: 2rem;
}

.sliderButton {
    font-size: 0.65rem;
    background-color: var(--color-web-2);
    border: none;
    padding: 0.85rem 1rem;
    text-transform: uppercase;
    z-index: 10;
    margin-top: 1.25rem;
    letter-spacing: unset;
    cursor: pointer;
    border-radius: 0.1rem;
    font-weight: 400;
    min-width: 9rem;
    margin-top: 2rem;
}

.sliderButton:hover {
    filter: brightness(120%);
}

.iconsContactFilter {
    filter: brightness(90%);
}

@media only screen and (orientation:portrait) {

    .form input::placeholder, .form textarea::placeholder {
        font-size: 14px;
    }

    .form input[name="apellido"]::placeholder {
        font-size: 11px;
    }

    .divContacto { 
        width: 50%;
        clip-path: polygon(0 0, 0% 100%, 100% 100%);
    }    

    .imgDivContacto {
        object-position: left top;
    }

    .seccion-contacto {
        justify-content: center;
    }

    .tel-contacto, .email-contacto {
        margin-bottom: 0.75rem;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
    }
  
    .tel-contacto h2, .email-contacto h2 {
        margin-right: 0;
    }

    .titulo-contacto {
        margin-top: 4rem;
    }

    .telEmailCont {
        justify-content: center;
    }

    .form {
        --grid-gap: 0.5rem;
        grid-template-areas: "titulo titulo" 
                             "nombre apellido"
                             "email telefono"
                             "mensaje mensaje";
    }

}    
