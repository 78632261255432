.contImgHome {
    width: 100%;
    min-height: calc(100vh - var(--navBar-height));
    overflow: hidden;
    position: relative;
    background-color: rgba(0, 0, 0, 0.918);
}

.imgHome {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: grayscale(55%) contrast(85%) brightness(70%);
    opacity: 0; 
    transition-duration: 2s;
    transition-property: opacity;                             
    transition-timing-function: ease-out;
    animation-duration: 16s;                             /*Tiempo de zoom de cada imagen (en el .jsx el setTimeout de la funcion carousel tiene que ser 1 segundo menos si el tiempo de cambio de opacidad es 1s)*/
    animation-timing-function: linear;
    animation-fill-mode: forwards;
}

.imgHomeTextCont {
    position: absolute;
    opacity: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    margin: auto;
    transition-duration: 2s;
    transition-property: opacity;                             
    transition-timing-function: ease-out;
}

.imgHomeText1Cont {
    margin-bottom: 2rem;
}

.imgHomeLogo {
    height: 3.5rem;
}

.imgHomeText1 {
    font-size: 1.5rem;
    font-weight: 500;
    margin-top: 0.5rem;
}

.imgHomeText2 {
    font-size: 2.75rem;
    font-weight: 600;
}

.imgHomeLine {
    margin-top: 0.5rem;
    width: 12rem;
    filter: invert(75%);
}

.zoomIn {
    opacity: 1 !important;
    animation-name: zoomIn !important;
}

.zoomOut {
    opacity: 0 !important;
}

.textIn {
    opacity: 1 !important;
}

.textOut {
    opacity: 0 !important;
}

@keyframes zoomIn {
    0% {
        transform: scale(100%);
    }
    100% {
        transform: scale(125%);
    }
}

@media only screen and (orientation: landscape) and (width < 1200px) {

    .contImgHome {
        min-height: 100vh;
    }

}


