.homeTeamCard_cont {
    margin: 1rem 0.5rem;
    transition-duration: 0.25s;
    transition-timing-function: ease-out;
    transition-property: transform;
}

.scale105:hover {
    transform: scale(105%);
}

.scale89:hover {
    transform: scale(89.25%);
}

.homeTeamCard_ScaleDown {
    transform: scale(85%);
}

.homeTeamCard_viewCVText {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition-duration: 0.25s;
    transition-timing-function: ease-out;
    transition-property: opacity;
}

.homeTeamCard_viewCVText_Span {
    animation-duration: 0.25s;
    animation-timing-function: ease-out;
    animation-fill-mode: ease-out;
    font-size: 0.80rem;
    display: inline-block;
    line-height: 0;
}

@keyframes homeTeamTextfadeInLeft {
    0%{
        transform: translateX(-2rem);
    }
    100%{
        transform: translateX(0);
    }
}

.homeTeamCard_cont:hover .homeTeamCard_viewCVText {
    opacity: 1;
    background-color: var(--color-web-2);
}

.homeTeamCard_cont:hover .homeTeamCard_viewCVText_Span {
    animation-name: homeTeamTextfadeInLeft;
}
