@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap'); /* font-family: 'Poppins', sans-serif; */
@import url('https://fonts.googleapis.com/css2?family=Dynalight&display=swap') /*font-family: 'Dynalight', cursive; */;

:root {
    --color-web-1: #282E3F;
    --color-web-2: #333A4D;
    --color-web-3: #BF884E;
    --color-web-4: #CF9455;
 
    --font-style-principal: 'Poppins', sans-serif;
    --text-light-color: rgb(255, 255, 255);
    --text-dark-color: var(--color-web-1);

    --navBar-height: 6rem;
    --navBar-border-lines-color: #3f4555;
    --color-navbar-hover: #d19f6a;

    --concerns-cards-width: 15rem;
    --concerns-cards-height: 15rem;
    --concerns-cards-margin: 2rem;

    --footer-border-lines-color: #303441;

    --bg-filter: brightness(60%) contrast(90%);
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    line-height: 1.2;
    font-weight: 300;
    list-style: none;
    font-size: 16px;
    font-family: var(--font-style-principal);
    color: var(--text-light-color);
    text-align: center;
}

body {
    position: relative;
}

a {
    text-decoration: none;
    color: inherit;
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
}

.column {
    flex-direction: column;
}

.wrap {
    flex-wrap: wrap;
}

.opb {
    object-position: bottom;
}

.opt {
    object-position: top;
}

.opc {
    object-position: center;
}

.opr {
    object-position: right;
}

.opl {
    object-position: left;
}

@media only screen and (orientation: landscape) {
    html {
        font-size: calc(1vh + 14px);
    }
}

@media only screen and (orientation: portrait) {
    html {
        font-size: calc(1vw + 14px);
    }
}